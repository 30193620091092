/* Ofice of Comunication Styles Start*/

header,
div.Content {
	max-width: none !important;
}

h3,
h4,
h5,
h6 {
	color: #212121 !important;
}

/* Ofice of Comunication Header & Footer Styles Start*/
#access a:link,
#access a:visited {
	color: #333333 !important;
	text-decoration: none;
}

#access a:hover,
#access a:active,
#access a:focus {
	color: #CC3333 !important;
	text-decoration: underline;
}

#top a:link,
#top a:visited,
#footer a:link,
#footer a:visited {
	color: #e0ebf6 !important;
	text-decoration: none;
}

#top a:hover,
#top a:active,
#top a:focus,
#footer a:hover,
#footer a:active,
#footer a:focus,
#bottom a:hover,
#bottom a:active,
#bottom a:focus {
	color: #F7F7F7 !important;
	text-decoration: underline;
}

td #header_links a:link,
#header_links a:visited {
	color: #888888 !important;
}

td #header_links a:hover,
td #header_links a:active {
	color: #b2202b !important;
}

/* Ofice of Comunication Header & Footer Styles End*/

/* Ofice of Comunication Side Nav Styles Start*/
/* --- top level links --- */
#mainnav a {
	color: #F7F7F7 !important;
}

/* --- top level hover style --- */
#mainnav a:hover,
#mainnav a:active,
#mainnav a:focus {
	color: #002F86 !important;
}

/* --- second level links --- */
#mainnav ul li a {
	color: #002F86 !important;
}

/* --- second level hover --- */
#mainnav ul li a:hover,
#mainnav ul li a:active,
#mainnav ul li a:focus {
	color: #002F86 !important;
}

/* --- third level links ---
Dark #002F86
Medium #255aad
light #e0ebf6	 */
#mainnav ul li ul li a {
	color: #002F86 !important;
}

/* --- third level hover --- */
#mainnav ul li ul li a:hover,
#mainnav ul li ul li a:active,
#mainnav ul li ul li a:focus {
	color: #b2202b !important;
}

/* Ofice of Comunication Side Nav Styles End*/

/* Ofice of Comunication Styles End*/

/* Common Styles  */
/*artf286340 : BR 21.6 Image Viewer*/
.modal-header {
	background-color: #336699;
	color: white;
}

.searchResultsImageSize {
	width: 150px !important;
	height: 150px !important;
}

fieldset {
	padding: 0 !important
}

.secondaryButton {
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
	color: #336699 !important;
	border: 1px solid #336699 !important;
	background-color: #FFFFFF !important;
	text-transform: capitalize;
}